<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useGameContentStore } from '~/store/GameContent'
  import useTranslation from '~/composables/Translation/Translation'
  import { useGameStateStore } from '~/store/GameState'
  import { useGameProgressStayStore } from '~/store/progress/GameProgressStay'

  const gameStateStore = useGameStateStore()
  const gameContentStore = useGameContentStore()
  const gameProgressStayStore = useGameProgressStayStore()

  const { t, tMd } = useTranslation()
  const { activeModeId } = storeToRefs(gameStateStore)
  const { cityContent } = storeToRefs(gameContentStore)
  const { isActiveMode, hasUnlockedAllDistricts } = storeToRefs(gameProgressStayStore)
</script>

<template>
  <div class="c-sidebar-content-city s-sidebar-content u-typography-root">
    <h1 v-if="cityContent" class="s-sidebar-content__title">
      {{ cityContent.name }}
    </h1>
    <div
      class="c-sidebar-content-city__introduction u-markdown-root"
      v-html="tMd('sidebar-content-city.introduction')"
    />

    <div class="s-sidebar-content__entries">
      <h2 class="s-sidebar-content__entries-title">
        {{ t('sidebar-content-city.districts') }}
      </h2>
      <p class="u-reset s-sidebar-content__instructions">
        {{ t('sidebar-content-city.instructions') }}
      </p>
      <p
        v-if="isActiveMode && !hasUnlockedAllDistricts"
        class="u-reset s-sidebar-content__instructions"
      >
        {{ t('sidebar-content-city.unlock') }}
      </p>

      <ListDistrict
        v-if="cityContent"
        class="s-sidebar-content__entries-content"
        :city-id="cityContent.id"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '/assets/scss/base/typography/typography' as type;

  .c-sidebar-content-city__instructions-text {
    @include type.copy-small;
  }
</style>
